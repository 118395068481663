<template>
  <div class="deposit_edit world">
    <div class="form">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-form-inline form">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" :disabled="type=='1'" class="w205" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNumber">
          <el-input v-model="ruleForm.idNumber" :disabled="type=='1'" class="w205" placeholder="请输入身份证号"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="ruleForm.sex" :disabled="type=='1'" class="marL10 lineH40 transformY5">
            <el-radio label="6">男</el-radio>
            <el-radio label="7">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="残疾类型" prop="distabledType">
          <el-select :disabled="type=='1'" class="marR10 w205" placeholder="请选择残疾类型" v-model="ruleForm.distabledType">
            <el-option v-for="(item,index) in disTypeList" :key="index" :label="item.text" :value="item.id">
              {{ item.text }}
            </el-option>
          </el-select>
          <el-select v-model="ruleForm.distabledLevel" :disabled="type=='1'" placeholder="请选择残疾等级" class="w205 marL5">
            <el-option v-for="(item,index) in disLevelList" :key="index" :label="item.text" :value="item.id">
              {{ item.text }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="残疾证号" prop="disabledNumber">
          <el-input v-model="ruleForm.disabledNumber" :disabled="type=='1'" class="w430"
                    placeholder="请输入残疾证号"></el-input>
        </el-form-item>
        <el-form-item label="残疾证" prop="disableCard">
          <div class="prompt paddB10 color99 fonT12">注：上传残疾证的扫描件或照片，最多不超过5张</div>
          <el-upload
              action="/api/app-jycy-disabled/uploadImg"
              class="avatar-uploader avatar-uploader_certificate"
              list-type="picture-card"
              ref="cjz"
              :limit="5"
              :headers="uploadHeaders"
              :disabled="type=='1'"
              :on-remove="(file)=>{removeFile(file,'cjz')}"
              :on-exceed="handleExceed"
              :file-list="hxd"
              :on-success="(res)=>{handleCertificateSuccess(res,'cjz')}"
              accept=".jpg,.png"
              :before-upload="beforeAvatarUpload">
            <img src="~/static/center/img_add.png" alt="" class="block_inline img">
          </el-upload>
        </el-form-item>
        <el-form-item label="身份证" prop="idPros">
          <div class="prompt paddB10 color99 fonT12">注：上传身份证正反面扫描件或照片</div>
          <div class="id_photo flex">
            <div class="left marginR20">
              <el-upload
                  action="/api/app-jycy-disabled/uploadImg"
                  class="avatar-uploader inline-block avatar-uploader_certificate"
                  :headers="uploadHeaders"
                  list-type="picture-card"
                  ref="sfzf"
                  :on-success="(res)=>{handleCertificateSuccess(res,'sfzz')}"
                  :show-file-list="false"
                  accept=".jpg,.png"
                  :disabled="type=='1'"
                  :before-upload="beforeAvatarUpload">
                <img v-show="ruleForm.idPros" :src="ruleForm.idPros" class="avatar">
                <img src="~/static/center/img_add.png" alt="" v-show="!ruleForm.idPros" class="block_inline img">
              </el-upload>
              <p class="text_c color66">身份证正面</p>
            </div>
            <div class="right">
              <el-upload
                  action="/api/app-jycy-disabled/uploadImg"
                  class="avatar-uploader inline-block avatar-uploader_certificate"
                  :headers="uploadHeaders"
                  list-type="picture-card"
                  ref="sfzf"
                  :on-success="(res)=>{handleCertificateSuccess(res,'sfzf')}"
                  :show-file-list="false"
                  accept=".jpg,.png"
                  :disabled="type=='1'"
                  :before-upload="beforeAvatarUpload">
                <img v-show="ruleForm.idCons" :src="ruleForm.idCons" class="avatar">
                <img src="~/static/center/img_add.png" alt="" v-show="!ruleForm.idCons" class="block_inline img">
              </el-upload>
              <p class="text_c color66">身份证反面</p>
            </div>
          </div>

        </el-form-item>
        <el-form-item label="社保卡" prop="socialSecurityCardPros">
          <div class="prompt paddB10 color99 fonT12">注：上传社保卡正反面扫描件或照片</div>
          <div class="id_photo flex">
            <div class="left marginR20">
              <el-upload
                  :disabled="type=='1'"
                  action="/api/app-jycy-disabled/uploadImg"
                  class="avatar-uploader inline-block avatar-uploader_certificate"
                  :headers="uploadHeaders"
                  list-type="picture-card"
                  :multiple:="false"
                  :on-success="(res)=>{handleCertificateSuccess(res,'sbz')}"
                  :show-file-list="false"
                  accept=".jpg,.png"
                  :before-upload="beforeAvatarUpload">
                <img v-show="ruleForm.socialSecurityCardPros" :src="ruleForm.socialSecurityCardPros" class="avatar">
                <img src="~/static/center/img_add.png" alt="" v-show="!ruleForm.socialSecurityCardPros"
                     class="block_inline img">
              </el-upload>
              <p class="text_c color66">社保卡正面</p>
            </div>
            <div class="right">
              <el-upload
                  :disabled="type=='1'"
                  action="/api/app-jycy-disabled/uploadImg"
                  class="avatar-uploader inline-block avatar-uploader_certificate"
                  :headers="uploadHeaders"
                  list-type="picture-card"
                  :on-success="(res)=>{handleCertificateSuccess(res,'sbf')}"
                  :show-file-list="false"
                  accept=".jpg,.png"
                  :before-upload="beforeAvatarUpload">
                <img v-show="ruleForm.socialSecurityCardCons" :src="ruleForm.socialSecurityCardCons" class="avatar">
                <img src="~/static/center/img_add.png" alt="" v-show="!ruleForm.socialSecurityCardCons"
                     class="block_inline img">
              </el-upload>
              <p class="text_c color66">社保卡反面</p>
            </div>
          </div>

        </el-form-item>
        <el-form-item label="劳动合同">
          <div class="prompt paddB10 color99 fonT12">注：上传残疾人与贵单位签署的劳动合同的扫描件或照片，最多不超过10张</div>
          <el-upload
              action="/api/app-jycy-disabled/uploadImg"
              class="avatar-uploader avatar-uploader_certificate"
              :headers="uploadHeaders"
              list-type="picture-card"
              :limit="10"
              :disabled="type=='1'"
              :file-list="hxs"
              :on-remove="(file)=>{removeFile(file,'ldht')}"
              :on-success="(res)=>{handleCertificateSuccess(res,'ldht')}"
              accept=".jpg,.png"
              :before-upload="beforeAvatarUpload">
            <img src="~/static/center/img_add.png" class="block_inline img">
          </el-upload>
        </el-form-item>
        <el-form-item class="text_c buttons">
          <el-button type="primary" class="w170" v-if="type!='1'" :loading="loading" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "depositEdit",
  props: ['id', 'type'],
  data() {
    return {
      loading: false,
      disTypeList: [
        {text: '视力残疾', id: '1'},
        {text: '听力残疾', id: '2'},
        {text: '言语残疾', id: '3'},
        {text: '肢体残疾', id: '4'},
        {text: '智力残疾', id: '5'},
        {text: '精神残疾', id: '6'},
        {text: '多重残疾', id: '80'},
      ],
      laborContract: [],
      disLevelList: [
        {text: '一级', id: '1'},
        {text: '二级', id: '2'},
        {text: '三级', id: '3'},
        {text: '四级', id: '4'},
        {text: '一级（军/警）', id: '11'},
        {text: '二级（军/警）', id: '12'},
        {text: '三级（军/警）', id: '13'},
        {text: '四级（军/警）', id: '14'},
        {text: '五级（军/警）', id: '15'},
        {text: '六级（军/警）', id: '16'},
        {text: '七级（军/警）', id: '17'},
        {text: '八级（军/警）', id: '18'},
        {text: '九级（军/警）', id: '19'},
        {text: '十级（军/警）', id: '20'},],
      hxd: [],
      disableCard: [],
      hxs: [],
      ruleForm: {
        name: '',
        id: '',
        gender: '',
        type: '',
        level: '',
        ids: '',
        certificate: '',
        idPhoto: '',
        idPhotoEnd: '',
        card: '',
        idPros: '',  //身份证正面
        idCons: '',  //身份证反面
        socialSecurityCardPros: '',  //社保卡正面
        socialSecurityCardCons: '',  //社保卡反面
        laborContract: ''
      },
      rules: {
        name: [
          {required: true, message: '请填写姓名', trigger: 'blur'},
        ],
        distabledType: [
          {required: true, message: '残疾类型不能为空', trigger: 'blur'},
        ],
        distabledLevel: [
          {required: true, message: '残疾类型不能为空', trigger: 'change'}
        ],
        idNumber: [
          {required: true, message: '身份证号不能为空', trigger: 'change'},
          {min: 18, message: '身份证号最大为18个字符', trigger: 'blur'}

        ],
        disabledNumber: [
          {required: true, message: '残疾证号不能为空', trigger: 'change'},
          {max: 22, message: '残疾证号最大为22个字符', trigger: 'blur'}
        ],
        sex: [
          {required: true, message: '性别不能为空', trigger: 'change'}
        ],
        disableCard: [
          {required: true, message: '残疾证照片不能为空', trigger: 'change'}
        ],
        idPros: [
          {required: true, message: '身份证正面照片不能为空', trigger: 'change'}
        ],
        idCons: [
          {required: true, message: '身份证反面照片不能为空', trigger: 'change'}
        ],
        socialSecurityCardPros: [
          {required: true, message: '社保卡正面照片不能为空', trigger: 'change'}
        ],
        socialSecurityCardCons: [
          {required: true, message: '社保卡反面照片不能为空', trigger: 'change'}
        ],
      },
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M
    },
    removeFile: function (file, name) {
      if (name == 'ldht') {
        if (file.response) {
          this.laborContract.forEach((item, i) => {
            if (item == file.response.msg) {
              this.laborContract.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        } else {
          this.laborContract.forEach((item, i) => {
            if (item == file.url) {
              this.laborContract.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        }
      } else if (name == 'cjz') {
        if (file.response) {
          this.disableCard.forEach((item, i) => {
            if (item == file.response.msg) {
              this.disableCard.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        } else {
          this.disableCard.forEach((item, i) => {
            if (item == file.url) {
              this.disableCard.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
            }
          })
        }
      }
    },
    handleCertificateSuccess(res, index) {
      if (index == 'cjz') {
        this.disableCard.push(res.msg);
        this.ruleForm.disableCard = "1";
      } else if (index == 'sfzz') {
        this.ruleForm.idPros = res.msg;
        console.log(this.ruleForm.idPros)
      } else if (index == 'sfzf') {
        this.ruleForm.idCons = res.msg;
      } else if (index == 'sbz') {
        this.ruleForm.socialSecurityCardPros = res.msg;
      } else if (index == 'sbf') {
        this.ruleForm.socialSecurityCardCons = res.msg;
      } else if (index == 'ldht') {
        this.laborContract.push(res.msg + '');
      }
    },
    dl() {
      console.log("3");
      if (this.distype) {
        console.log("d" + this.distype);
        this.ruleForm.distabledType = this.distype;
        this.getLevelTypeList();

      }

    },

    idchage(val) {
      this.hxd = [];
      this.hxs = [];
      if (val == "") {
        console.log("id====" + val);
        this.ruleForm = {
          name: '',
          id: '',
          gender: '',
          type: '',
          level: '',
          ids: '',
          certificate: '',
          idPhoto: '',
          idPhotoEnd: '',
          card: '',
          idPros: '',  //身份证正面
          idCons: '',  //身份证反面
          socialSecurityCardPros: '',  //社保卡正面
          socialSecurityCardCons: '',  //社保卡反面
        }
      } else {
        new Promise(resolve => {
          let id = this.id;
          if (id) {
            this.$api.lanzhouGetDisabledApi(id).then(ref => {
              let data = ref.data.data;
              if (data.laborContract) {
                let d = data.laborContract.split(";");
                for (var i = 0; i <= d.length - 1; i++) {
                  const obf = {};
                  obf.url = d[i];
                  this.hxs.push(obf);
                }
              }
              let d = data.disableCard.split(";");
              for (var i = 0; i <= d.length - 1; i++) {
                const obf = {};
                obf.url = d[i];
                this.hxd.push(obf);
              }
              console.log(5555, data)

              this.ruleForm = data;
              this.laborContract = data.laborContract.split(";");
              this.disableCard = data.disableCard.split(";");
              console.log(this.laborContract);
              console.log(666, this.ruleForm)
              resolve("2");
            })

          }
        }).then(value => {
          this.dl();
        })
      }
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前上传图片达到上限！`);
    },
    getid: function () {

    },
    save() {
      this.ruleForm.id = this.id;
      this.$refs["ruleForm"].validate(valid => {
            if (valid) {
              let ruleForm = this.ruleForm;
              ruleForm.laborContract = this.laborContract.join(";");
              ruleForm.disableCard = this.disableCard.join(";");
              this.loading = true;
              this.$api.lanzhouUpdateDisabledApi(ruleForm).then(response => {
                if (response.data.success) {
                  this.loading = false;
                  this.$message.success('保存成功！');
                  this.$emit('updateDisable', "");

                } else {
                  this.$message.error(response.msg);
                  this.loading = false;
                }

              })

            }
          }
      )
    }
  },

  created() {
    this.idchage(this.id);
    console.log("id==cregd==" + this.id, 111111111);

  },
  watch: {

    id(val) {
      console.log("id=" + val);
      this.idchage(val);

    }
  }
}
</script>

<style scoped lang="less">
@import "~assets/lanzhou/public.less";
@import "~assets/lanzhou/deposit.less";

.form {
  .el-form-item {
    .avatar-uploader {
      .el-upload {
        width: 100px;
        height: 100px;
      }
    }
  }

  .buttons {
    margin-top: 70px;
  }

  .avatar-uploader {
    .avatar {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
